<template>
  <div class="">
    <div class="">
      <h4 class="card-title">Settings</h4>
      <div class="row">
        <div class="col-9">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <router-link :to="{ query: { tab: 'info' }}" :class="`nav-link ${show_tab == 'info' ? 'active' : ''}`">App Info</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ query: { tab: 'danger' }}" :class="`nav-link ${show_tab == 'danger' ? 'active' : ''}`">Danger Zone</router-link>
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div :class="`tab-pane ${show_tab == 'info' ? 'active' : ''}`" id="info">
              <div>
                <form @submit.prevent="updateApp">
                  <div class="form-group">
                    <label>App Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="app.name"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      placeholder="Description"
                      v-model="app.description"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary btn-sm">
                      <i class="lni-save mr-2"></i> Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div :class="`tab-pane ${show_tab == 'danger' ? 'active' : ''}`" id="danger-zone">
              <div>
                <h4>Danger Zone</h4>
                <hr />
                <p>
                  Deleting your app is a permanent action that cannot be
                  reversed.mtenders
                </p>
                <p>
                  To transfer ownership,
                  <router-link :to="{ name: 'app.members' }">go to app members</router-link>
                </p>
                <div class="form-group">
                  <label>Enter the name of the app to proceed</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="deletion_confirmation"
                    placeholder="Enter the full app name"
                  />
                </div>
                <a
                  href="#"
                  class="btn btn-danger"
                  @click.prevent="deleteApp"
                  v-if="deletion_confirmed"
                  ><i class="lni-times-circle-o mr-2"></i> Delete this app</a
                >
                <a
                  href="#"
                  class="btn btn-danger disabled"
                  v-if="!deletion_confirmed"
                  ><i class="lni-times-circle-o mr-2"></i> Delete this app</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deletion_confirmation: "",
    };
  },

  methods: {
    updateApp() {
      let data = this.app;

      this.$loader.start('main-content')
      this.$axios.put(`/api/v1/apps/${this.$route.params.id}`, data).then((response) => {
        this.$store.dispatch("setApp", response.data.current_app);
        this.$loader.stop()
      });
    },

    deleteApp() {
      this.$loader.start('main-content')
      this.$axios.delete(`/api/v1/apps/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: "dashboard" });
        this.$loader.stop()
      });
    },
  },

  computed: {
    app() {
      return this.$store.getters.getApp;
    },

    deletion_confirmed() {
      if (this.deletion_confirmation === this.$store.getters.getApp.name) {
        return true;
      }

      return false;
    },

    show_tab() {
      return this.$route.query.tab == null ? 'info' : this.$route.query.tab
    }
  },
};
</script>
